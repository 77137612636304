@font-face {
    font-family: 'TNP-Montserrat';
    src: url('Montserrat-Bold.eot');
    src: url('Montserrat-Bold.eot?#iefix') format('embedded-opentype'),
        url('Montserrat-Bold.woff2') format('woff2'),
        url('Montserrat-Bold.woff') format('woff'),
        url('Montserrat-Bold.ttf') format('truetype'),
        url('Montserrat-Bold.svg#Montserrat-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'TNP-Montserrat';
    src: url('Montserrat-BoldItalic.eot');
    src: url('Montserrat-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('Montserrat-BoldItalic.woff2') format('woff2'),
        url('Montserrat-BoldItalic.woff') format('woff'),
        url('Montserrat-BoldItalic.ttf') format('truetype'),
        url('Montserrat-BoldItalic.svg#Montserrat-BoldItalic') format('svg');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'TNP-Montserrat';
    src: url('Montserrat-ExtraBoldItalic.eot');
    src: url('Montserrat-ExtraBoldItalic.eot?#iefix') format('embedded-opentype'),
        url('Montserrat-ExtraBoldItalic.woff2') format('woff2'),
        url('Montserrat-ExtraBoldItalic.woff') format('woff'),
        url('Montserrat-ExtraBoldItalic.ttf') format('truetype'),
        url('Montserrat-ExtraBoldItalic.svg#Montserrat-ExtraBoldItalic') format('svg');
    font-weight: 800;
    font-style: italic;
}

@font-face {
    font-family: 'TNP-Montserrat';
    src: url('Montserrat-ExtraBold.eot');
    src: url('Montserrat-ExtraBold.eot?#iefix') format('embedded-opentype'),
        url('Montserrat-ExtraBold.woff2') format('woff2'),
        url('Montserrat-ExtraBold.woff') format('woff'),
        url('Montserrat-ExtraBold.ttf') format('truetype'),
        url('Montserrat-ExtraBold.svg#Montserrat-ExtraBold') format('svg');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'TNP-Montserrat';
    src: url('Montserrat-Black.eot');
    src: url('Montserrat-Black.eot?#iefix') format('embedded-opentype'),
        url('Montserrat-Black.woff2') format('woff2'),
        url('Montserrat-Black.woff') format('woff'),
        url('Montserrat-Black.ttf') format('truetype'),
        url('Montserrat-Black.svg#Montserrat-Black') format('svg');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'TNP-Montserrat';
    src: url('Montserrat-BlackItalic.eot');
    src: url('Montserrat-BlackItalic.eot?#iefix') format('embedded-opentype'),
        url('Montserrat-BlackItalic.woff2') format('woff2'),
        url('Montserrat-BlackItalic.woff') format('woff'),
        url('Montserrat-BlackItalic.ttf') format('truetype'),
        url('Montserrat-BlackItalic.svg#Montserrat-BlackItalic') format('svg');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'TNP-Montserrat';
    src: url('Montserrat-SemiBold.eot');
    src: url('Montserrat-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('Montserrat-SemiBold.woff2') format('woff2'),
        url('Montserrat-SemiBold.woff') format('woff'),
        url('Montserrat-SemiBold.ttf') format('truetype'),
        url('Montserrat-SemiBold.svg#Montserrat-SemiBold') format('svg');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'TNP-Montserrat';
    src: url('Montserrat-Medium.eot');
    src: url('Montserrat-Medium.eot?#iefix') format('embedded-opentype'),
        url('Montserrat-Medium.woff2') format('woff2'),
        url('Montserrat-Medium.woff') format('woff'),
        url('Montserrat-Medium.ttf') format('truetype'),
        url('Montserrat-Medium.svg#Montserrat-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'TNP-Montserrat';
    src: url('Montserrat-Light.eot');
    src: url('Montserrat-Light.eot?#iefix') format('embedded-opentype'),
        url('Montserrat-Light.woff2') format('woff2'),
        url('Montserrat-Light.woff') format('woff'),
        url('Montserrat-Light.ttf') format('truetype'),
        url('Montserrat-Light.svg#Montserrat-Light') format('svg');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'TNP-Montserrat';
    src: url('Montserrat-ExtraLight.eot');
    src: url('Montserrat-ExtraLight.eot?#iefix') format('embedded-opentype'),
        url('Montserrat-ExtraLight.woff2') format('woff2'),
        url('Montserrat-ExtraLight.woff') format('woff'),
        url('Montserrat-ExtraLight.ttf') format('truetype'),
        url('Montserrat-ExtraLight.svg#Montserrat-ExtraLight') format('svg');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'TNP-Montserrat';
    src: url('Montserrat-Italic.eot');
    src: url('Montserrat-Italic.eot?#iefix') format('embedded-opentype'),
        url('Montserrat-Italic.woff2') format('woff2'),
        url('Montserrat-Italic.woff') format('woff'),
        url('Montserrat-Italic.ttf') format('truetype'),
        url('Montserrat-Italic.svg#Montserrat-Italic') format('svg');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'TNP-Montserrat';
    src: url('Montserrat-SemiBoldItalic.eot');
    src: url('Montserrat-SemiBoldItalic.eot?#iefix') format('embedded-opentype'),
        url('Montserrat-SemiBoldItalic.woff2') format('woff2'),
        url('Montserrat-SemiBoldItalic.woff') format('woff'),
        url('Montserrat-SemiBoldItalic.ttf') format('truetype'),
        url('Montserrat-SemiBoldItalic.svg#Montserrat-SemiBoldItalic') format('svg');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'TNP-Montserrat';
    src: url('Montserrat-ExtraLightItalic.eot');
    src: url('Montserrat-ExtraLightItalic.eot?#iefix') format('embedded-opentype'),
        url('Montserrat-ExtraLightItalic.woff2') format('woff2'),
        url('Montserrat-ExtraLightItalic.woff') format('woff'),
        url('Montserrat-ExtraLightItalic.ttf') format('truetype'),
        url('Montserrat-ExtraLightItalic.svg#Montserrat-ExtraLightItalic') format('svg');
    font-weight: 200;
    font-style: italic;
}

@font-face {
    font-family: 'TNP-Montserrat';
    src: url('Montserrat-MediumItalic.eot');
    src: url('Montserrat-MediumItalic.eot?#iefix') format('embedded-opentype'),
        url('Montserrat-MediumItalic.woff2') format('woff2'),
        url('Montserrat-MediumItalic.woff') format('woff'),
        url('Montserrat-MediumItalic.ttf') format('truetype'),
        url('Montserrat-MediumItalic.svg#Montserrat-MediumItalic') format('svg');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'TNP-Montserrat';
    src: url('Montserrat-Regular.eot');
    src: url('Montserrat-Regular.eot?#iefix') format('embedded-opentype'),
        url('Montserrat-Regular.woff2') format('woff2'),
        url('Montserrat-Regular.woff') format('woff'),
        url('Montserrat-Regular.ttf') format('truetype'),
        url('Montserrat-Regular.svg#Montserrat-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'TNP-Montserrat';
    src: url('Montserrat-ThinItalic.eot');
    src: url('Montserrat-ThinItalic.eot?#iefix') format('embedded-opentype'),
        url('Montserrat-ThinItalic.woff2') format('woff2'),
        url('Montserrat-ThinItalic.woff') format('woff'),
        url('Montserrat-ThinItalic.ttf') format('truetype'),
        url('Montserrat-ThinItalic.svg#Montserrat-ThinItalic') format('svg');
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: 'TNP-Montserrat';
    src: url('Montserrat-LightItalic.eot');
    src: url('Montserrat-LightItalic.eot?#iefix') format('embedded-opentype'),
        url('Montserrat-LightItalic.woff2') format('woff2'),
        url('Montserrat-LightItalic.woff') format('woff'),
        url('Montserrat-LightItalic.ttf') format('truetype'),
        url('Montserrat-LightItalic.svg#Montserrat-LightItalic') format('svg');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'TNP-Montserrat';
    src: url('Montserrat-Thin.eot');
    src: url('Montserrat-Thin.eot?#iefix') format('embedded-opentype'),
        url('Montserrat-Thin.woff2') format('woff2'),
        url('Montserrat-Thin.woff') format('woff'),
        url('Montserrat-Thin.ttf') format('truetype'),
        url('Montserrat-Thin.svg#Montserrat-Thin') format('svg');
    font-weight: 100;
    font-style: normal;
}

