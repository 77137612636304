// Spinning
// ----------------------

@-webkit-keyframes spinning {
    from { transform: rotate(0deg);   }
    to   { transform: rotate(360deg); }
}

@-moz-keyframes spinning {
    from { transform: rotate(0deg);   }
    to   { transform: rotate(360deg); }
}

@keyframes spinning {
    from { transform: rotate(0deg);   }
    to   { transform: rotate(360deg); }
}


// Pulse
// ----------------------

@-webkit-keyframes pulsing {
    0%, 100% { transform: scale(1); }
    50%      { transform: scale(4); }
}

@-moz-keyframes pulsing {
    0%, 100% { transform: scale(1); }
    50%      { transform: scale(4); }
}

@keyframes pulsing {
    0%, 100% { transform: scale(1); }
    50%      { transform: scale(4); }
}

@-webkit-keyframes pulse {
    0% {
        transform: scale(0.2);
        opacity: 0;
    }
    33% {
        transform: scale(0.6);
        opacity: 1;
    }
    100% {
        transform: scale(1.2);
        opacity: 0;
    }
}

@-moz-keyframes pulse {
    0% {
        transform: scale(0.2);
        opacity: 0;
    }
    33% {
        transform: scale(0.6);
        opacity: 1;
    }
    100% {
        transform: scale(1.2);
        opacity: 0;
    }
}

@keyframes pulse {
    0% {
        transform: scale(0.2);
        opacity: 0;
    }
    33% {
        transform: scale(0.6);
        opacity: 1;
    }
    100% {
        transform: scale(1.2);
        opacity: 0;
    }
}


// Clock dots
// ----------------------

@-webkit-keyframes clockDots {
    0%   { opacity: 1; }
    30%  { opacity: 1; }
    50%  { opacity: 0; }
    80%  { opacity: 0; }
    100% { opacity: 1; }
}

@-moz-keyframes clockDots {
    0%   { opacity: 1; }
    30%  { opacity: 1; }
    50%  { opacity: 0; }
    80%  { opacity: 0; }
    100% { opacity: 1; }
}

@keyframes clockDots {
    0%   { opacity: 1; }
    30%  { opacity: 1; }
    50%  { opacity: 0; }
    80%  { opacity: 0; }
    100% { opacity: 1; }
}
