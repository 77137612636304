@import './definitions.scss';

* {
    margin: 0;
    padding: 0;
    border: 0;
}

*,
[tabindex]:focus {
    outline: 0 !important;
}

*,
*::before,
*::after {
    // Change from `box-sizing: content-box` so that `width` is not affected by `padding` or `border`.
    box-sizing: border-box;
}

html {
    font-family: sans-serif;
    font-size: 16px;
    line-height: 1.15;
    // Prevent adjustments of font size after orientation changes in IE on Windows Phone and in iOS.
    -webkit-text-size-adjust: 100%;
    // Change the default tap highlight to be completely transparent in iOS.
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

html,
body {
    height: 100%;
    text-align: left;
    color: $color-text-primary;
    text-rendering: optimizeLegibility;
    font-family: $font-default-family;
    -webkit-font-smoothing: subpixel-antialiased;
    -moz-osx-font-smoothing: auto;
}

body {
    font-size: inherit;
    line-height: inherit;
    font-weight: normal;
}

div, svg, img, video, article, aside, figcaption,
figure, footer, header, hgroup, main, nav, section {
    display: block;
}

b,
strong {
    font-weight: bold;
}

em {
    font-style: italic;
}

small {
    font-size: 90%;
}

sub,
sup {
    position: relative;
    font-size: 75%;
    line-height: 0;
    vertical-align: baseline;
}

sub {
    bottom: -0.25em;
}

sup {
    top: -0.5em;
}

a {
    cursor: pointer;
    color: $color-link-primary;
    text-decoration: none;
    background-color: transparent;

    &:hover {
        text-decoration: underline;
    }

    //&:not([href]):not([tabindex]),
    //&:not([href]):not([tabindex]):hover,
    //&:not([href]):not([tabindex]):focus {
    //    cursor: default;
    //    color: inherit;
    //    text-decoration: none;
    //}
}

fieldset {
    display: block;
    min-width: 0;  // because default is: min-width: -webkit-min-content;
}

textarea,
button,
input {
    font-family: inherit;
}

button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
    padding: 0;
    border: 0 none;
}

input[type='text'],
input[type='password'] {
    vertical-align: middle;
}

textarea {
    overflow: auto;
    resize: vertical;
}

input[type='text'],
input[type='password'],
input[type='number'],
textarea {
    font-family: inherit;
}

table {
    border-spacing: 0;
    border-collapse: collapse;
}

th {
    text-align: inherit;
}

pre,
code,
kbd,
samp {
    font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
    font-size: 1em;
}

pre {
    overflow: auto;
}

svg {
    overflow: hidden;
    cursor: inherit;
}

ul,
ol {
    list-style: none;
}

hr {
    box-sizing: content-box;
    height: 0;
    overflow: visible;
}

template {
    display: none !important;
}
