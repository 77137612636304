@import './definitions.scss';

.select {
    $font-size: 14px;
    $line-height: ($font-size + 6px);
    $padding-y: (($control-height-regular - $line-height - ($control-border-size * 2)) / 2);

    display: inline-flex;
    color: $color-text-primary;
    font-size: $font-size;
    line-height: $line-height;
    font-family: inherit;
    font-weight: $font-weight-semi-bold;
    text-align: left;
    border-radius: $control-primary-radius;
    background: #fff url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMiIgaGVpZ2h0PSI4IiB2aWV3Qm94PSIwIDAgMTIgOCI+DQogICAgPHBhdGggZmlsbD0iI2I0YjhiYyIgZD0iTTYsNy40MSwxLjI5LDIuNzFBMSwxLDAsMCwxLDIuNzEsMS4yOUw2LDQuNTlsMy4yOS0zLjNhMSwxLDAsMSwxLDEuNDIsMS40MloiLz4NCjwvc3ZnPg0K') no-repeat right 9px center;
    appearance: none;
    box-sizing: border-box;
    max-width: 100%;
    cursor: default;
    border: $control-border-size solid $color-input-deco-color;
    box-shadow: $control-box-shadow;

    &::-ms-expand {
        display: none;
    }

    &_regular {
        height: $control-height-regular;
        min-width: 60px;
        padding: $padding-y 30px $padding-y 10px;
    }

    &_no-border {
        border-color: transparent;
    }

    fieldset:disabled &,
    &:disabled {
        box-shadow: none;
        transform: none;
        pointer-events: none;
        opacity: 0.7;
        cursor: default;
    }

    optgroup {
        background-color: #e6e6e6;
    }

    option {
        font-size: $font-size;
        line-height: $line-height;
        font-weight: $font-weight-medium;
        padding: 5px 10px;
        background-color: #fff;
    }
}
